import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import { motion } from "framer-motion";

const Header = ({ menuIsOpen, toggleMenu }) => {
  const variant = menuIsOpen ? "opened" : "closed";
  const top = {
    closed: {
      rotate: 0,
      y: 0,
    },
    opened: {
      rotate: -45,
      y: 8,
    },
  };
  const middle = {
    closed: {
      opacity: 1,
    },
    opened: {
      opacity: 0,
      transition: { duration: 0.1 },
    },
  };
  const bottom = {
    closed: {
      rotate: 0,
      y: 0,
    },
    opened: {
      rotate: 45,
      y: -8,
    },
  };
  return (
    <header className={menuIsOpen ? "header menu-header" : "header"}>
      <Link to="/" className="link">
        <div className="logo-div">
          <StaticImage
            src="../images/Logo-LM.png"
            alt="logo psychologe Lauren Maenen"
            className="logo"
            placeholder="none"
            loading="eager"
          />
          <span className="logo-text">
            Psychologe <br /> Lauren Maenen
          </span>
        </div>
      </Link>
      <nav className="header-nav-div">
        <ul className="nav-list">
          <li className="nav-item">
            <Link to="/aanbod" className="link">
              Aanbod
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/werkwijze" className="link">
              Werkwijze
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/praktische-info" className="link">
              Praktische info
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/contact" className="link">
              Contact
            </Link>
          </li>
        </ul>
      </nav>
      <div className="menu-button" onClick={toggleMenu}>
        <motion.svg
          width="35"
          height="35"
          viewBox="0 0 35 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          id="hamburger-icon"
          animate={variant}
        >
          <motion.rect
            width="35"
            height="3"
            rx="1.5"
            fill="#E5EAE4"
            variants={top}
          />
          <motion.rect
            y="8"
            width="35"
            height="3"
            rx="1.5"
            fill="#E5EAE4"
            variants={middle}
          />
          <motion.rect
            y="16"
            width="35"
            height="3"
            rx="1.5"
            fill="#E5EAE4"
            variants={bottom}
          />
        </motion.svg>
      </div>
    </header>
  );
};

export default Header;
