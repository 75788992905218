import React from "react";
import { Link } from "gatsby";

const Menu = ({ toggleMenu }) => {
  return (
    <div className="menu-overlay">
      <nav>
        <ul className="menu-nav-list">
          <li className="menu-nav-item">
            <Link to="/aanbod" className="link" onClick={toggleMenu}>
              Mijn aanbod
            </Link>
          </li>
          <li className="menu-nav-item">
            <Link to="/werkwijze" className="link" onClick={toggleMenu}>
              Werkwijze
            </Link>
          </li>
          <li className="menu-nav-item">
            <Link to="/praktische-info" className="link" onClick={toggleMenu}>
              Praktische info
            </Link>
          </li>
          <li className="menu-nav-item">
            <Link to="/contact" className="link" onClick={toggleMenu}>
              Contact
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Menu;
