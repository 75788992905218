import * as React from "react";
import { useState } from "react";
import Header from "./header";
import Menu from "./menu";
import Footer from "./footer";

import "./layout.css";
import "@fontsource/open-sans/400.css"; // Weight 500.
import "@fontsource/open-sans/600.css"; // Weight 600 - semibold.
import "@fontsource/open-sans/700.css"; // Weight 700 - bold.

const Layout = ({ children }) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const toggleMenu = () => {
    setMenuIsOpen(!menuIsOpen);
  };

  return (
    <>
      <Header menuIsOpen={menuIsOpen} toggleMenu={toggleMenu} />
      {menuIsOpen ? (
        <Menu toggleMenu={toggleMenu} />
      ) : (
        <main className="main-content">{children}</main>
      )}
      <Footer menuIsOpen={menuIsOpen} />
    </>
  );
};

export default Layout;
